<template>
  <div class="form-payment">
    <!-- <ModalHeader
      :left-text="''"
      :middle-text="'Silahkan Lengkapi Data Pembayaran Terpisah'"
      :left-function="directionPage"
      :is-rigt-column="true"
      :right-function="createTransaction"
      :is-disabled="isDisabledProcess()"
      :right-text="'Proses'"
      :customClassHeader="'header-payment'"
    /> -->
    <hr class="my-0">
    <div>
      <b-row class="mt-unset justify-content-between mt-2 mx-0">
        <b-col lg="6" md="6" cols="12" class="px-0">
          <div class="px-1" style="height: calc(100vh - 178.61px); overflow-y: auto;">
            <b-overlay
              :show="is_show_overlay"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <div
                class="container--multiple__payment"
                v-for="(payment, index) in payments"
                :key="index"
              >
                <b-form-group class="custom__form--input mb-2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="margin-bottom: 12px"
                  >
                    <label class="mb-0"
                      >Pilih Metode Pembayaran {{ index + 1 }}</label
                    >
                    <b-button
                      class="bg-white p-0"
                      @click="deletePayment(index)"
                      v-if="payments.length > 1"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        class="text-danger"
                      />
                    </b-button>
                  </div>
                  <b-form-select
                    v-model="payments[index].payment_method"
                    :options="options"
                    @change="onChangePaymentMethod(index)"
                  />
                </b-form-group>
                <Tunai
                  v-if="
                    payments &&
                    payments.length &&
                    payments[index].payment_method == 'tunai'
                  "
                  :indexNominal="index"
                  :formData="formPayload.payments[index]"
                  @payloadTunai="(value) => getPayload(value, index)"
                />
                <Deposit
                  v-if="
                    payments &&
                    payments.length &&
                    payments[index].payment_method == 'wallet'
                  "
                  :indexNominal="index"
                  :formData="formPayload.payments[index]"
                  @payloadDeposit="(value) => getPayloadDeposit(value, index)"
                  :isButtonHide="true"
                  :isSubmit="false"
                  :column="12"
                />
                <Giro
                  v-if="
                    payments &&
                    payments.length &&
                    payments[index].payment_method == 'giro'
                  "
                  :indexNominal="index"
                  :formData="formPayload.payments[index]"
                  @payloadGiro="(value) => getPayloadGiro(value, index)"
                  :isSubmit="false"
                  :isButtonHide="true"
                  :column="12"
                />
                <TransferBank
                  v-if="
                    payments &&
                    payments.length &&
                    payments[index].payment_method == 'transfer'
                  "
                  :indexNominal="index"
                  :formData="formPayload.payments[index]"
                  @payloadBank="(value) => getPayloadTransferBank(value, index)"
                  @bankAccountDetail="
                    (value) => getPayloadBankAccountDetail(value, index)
                  "
                  :isButtonHide="true"
                  :column="12"
                />
                <Kredit
                  v-if="
                    payments &&
                    payments.length &&
                    payments[index].payment_method == 'card'
                  "
                  :indexNominal="index"
                  :formData="formPayload.payments[index]"
                  @payloadCard="(value) => getPayloadKredit(value, index)"
                  :isButtonHide="true"
                  :isSubmit="false"
                  :column="12"
                />
                <Piutang
                  v-if="
                    payments &&
                    payments.length &&
                    payments[index].payment_method == 'piutang'
                  "
                  :indexNominal="index"
                  :formData="formPayload.payments[index]"
                  @payloadPiutang="(value) => getPayloadPiutang(value, index)"
                  :isButtonHide="true"
                  :isSubmit="false"
                  :column="12"
                />
                <hr />
              </div>
              <b-button
                class="w-100 mb-5 py-1 bg-white"
                @click="addPayments"
                :disabled="disabledAddPaymentMethod()"
              >
                + Tambah Metode Pembayaran
              </b-button>
            </b-overlay>
          </div>
        </b-col>
        <b-col lg="6" md="6" cols="12" style="position: relative">
          <div class="">
            <div class="text-center">
              <h6 class="text-dark text-darken-4 fw-bold-500 size14 mb-1">
                Total Transaksi
              </h6>
              <h5 class="size24 text-dark fw-bold-800 mb-1">
                {{ (parseFloat(transaction_query.total) + totalAddtionalCost) | formatAmount }}
              </h5>
              <h6
                v-if="transaction_query.bonus > 0"
                class="text-dark text-darken-4 size12 fw-bold-800"
              >
                Termasuk potongan diskon
                {{ parseFloat(transaction_query.bonus) | formatAmount }}
              </h6>
            </div>
            <div
              v-if="payments && payments[0].payment_method"
              class="mx-auto preview--detail"
            >
              <h6 class="size14 fw-bold-600 text-dark my-1 mx-auto">
                Metode Pembayaran
              </h6>
              <div
                class="d-flex justify-content-between align-items-center mx-auto mb-1"
                v-for="(payment, index) in payments"
                :key="`preview-${index}`"
              >
                <h6
                  :id="`account-name-${index}`"
                  class="size14 fw-bold-600 text-dark mb-0"
                >
                  {{ paymentMethods(payment.payment_method) }}
                </h6>
                <h6
                  :id="`nominal--${index}`"
                  class="size14 fw-bold-600 text-dark mb-0"
                >
                  {{ 0 | formatAmount }}
                </h6>
              </div>
              <div
                class="d-flex justify-content-between align-items-center mx-auto mb-1"
                v-if="parseFloat(transaction_query.total) + totalAddtionalCost - totalNominal > 0 "
              >
                <h6 class="size14 fw-bold-600 text-dark mb-0">Sisa</h6>
                <h6 class="size14 fw-bold-600 text-dark mb-0">
                  {{ (parseFloat(transaction_query.total) + totalAddtionalCost - totalNominal) | formatAmount }}
                </h6>
              </div>
              <hr />
              <div
                class="d-flex justify-content-between align-items-center mx-auto mb-1"
              >
                <h6 class="size14 fw-bold-600 text-dark mb-0">Total</h6>
                <h6 class="size14 fw-bold-600 text-dark mb-0">
                  {{ totalNominal | formatAmount }}
                </h6>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BImg,
  VBToggle,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
  BButton,
} from "bootstrap-vue";
import AppTour from "@core/components/app-tour/AppTour.vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
  formatRupiah,
} from "@/auth/utils";

import Deposit from "@/components/Payment/Deposit/FormStep1.vue";
import Giro from "@/components/Payment/Giro/FormStep1.vue";
import TransferBank from "@/components/Payment/TransferBank/index.vue";
import Kredit from "@/components/Payment/Kredit/FormStep1.vue";
import Piutang from "@/components/Payment/Piutang/FormStep1.vue";
import Tunai from "@/components/Payment/Tunai.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import TotalTransaksiComponent from "@/components/Transaction/TotalTransaksi.vue";

export default {
  title() {
    return "Pembayaran";
  },
  props: ['getActiveCart'],
  components: {
    BContainer,
    BCard,
    BImg,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    ModalHeader,
    AppTour,
    BFormSelect,
    BForm,
    Deposit,
    Giro,
    TransferBank,
    Kredit,
    Piutang,
    Tunai,
    TotalTransaksiComponent,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    ...mapState({
      formAdditionalCost: (state) => state.cart.formAdditionalCost,
      totalTransaksi: (state) => state.cart.totalTransaksi,
      customerUuid: (state) => state.customer.customer.uuid,
    }),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
      formatRupiah,
    };
  },
  data() {
    const transaction_query = JSON.parse(
      localStorage.getItem("transaction_query")
    );
    return {
      transaction_query,
      bank_account: null,
      total: localStorage.getItem("cash_total_payment"),
      userData: JSON.parse(localStorage.getItem("userData")),
      customer_uuid: localStorage.getItem("customer_uuid") || false,
      formPayload: {
        payments: [
          {
            payment_method: null,
            cash_total_payment: "",
          },
        ],
      },
      is_full_payment: JSON.parse(localStorage.getItem("is_full_payment")),
      payments: [
        {
          payment_method: null,
        },
      ],
      watchs: [],
      test: [],
      is_show_overlay: false,
      totalNominal: 0,
      totalAddtionalCost: 0,
      stepPayment: 2,
      options: [
        { value: null, text: "Pilih metode pembayaran" },
        {
          text: "Tunai",
          value: "tunai",
        },
        {
          text: "Transfer Bank",
          value: "transfer",
        },
        {
          text: "Kredit atau Debit",
          value: "card",
        },
        {
          text: "Giro",
          value: "giro",
        },
        {
          text: "Deposit",
          value: "wallet",
        },
        {
          text: "Piutang",
          value: "piutang",
          disabled: false,
        },
      ],
      swalOptions: {
        title: "Warning!",
        text: "",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      },
      isDisabled: false,
    };
  },
  watch: {
    watchs: {
      handler: _.debounce(function () {
        this.validationAmount();
      }, 300),
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    collectData() {
      this.totalAddtionalCost = 0;

      const additional_costs = JSON.parse(
        localStorage.getItem("additional_cost")
      );
      const payload_transaksi = JSON.parse(
        localStorage.getItem("payloadTransaksi")
      );

      this.formPayload.date = this.transaction_query.date;

      for (let index = 0; index < additional_costs.length; index++) {
        const element = additional_costs[index];
        this.totalAddtionalCost += parseFloat(
          preformatFloat(element.additional_fee_amount)
        );
      }

      this.formPayload = {
        ...this.formPayload,
        ...payload_transaksi,
        ...additional_costs,
      };

      this.updatedOptions();

      if (this.transaction_query.data) {
        const decodeData = this.transaction_query.data;
        this.is_show_overlay = true;
        setTimeout(() => {
          this.payments = decodeData;
          this.is_show_overlay = false;
          for (let index = 0; index < decodeData.length; index++) {
            const element = decodeData[index];
            if (document.getElementById(`nominal--${index}`)) {
              setTimeout(() => {
                document.getElementById(`nominal--${index}`).innerHTML = `Rp ${!element.cash_total_payment.match(".")
                    ? element.cash_total_payment
                    : element.cash_total_payment
                  }`;
              }, 300);
            }
            this.bankAccountName();
          }
        }, 500);
      }
    },
    bankAccountName() {
      for (let index = 0; index < this.payments.length; index++) {
        const element = this.payments[index];
        setTimeout(() => {
          const accountName = document.getElementById(`account-name-${index}`);
          if (element.payment_method == "transfer" && accountName) {
            accountName.innerHTML = `Transfer ke ${
              element.account_detail?.bank?.name || ""
            }`;
          }
        }, 500);
      }
    },
    rupiah(number) {
      return new Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number);
    },
    onChangePaymentMethod(index) {
      setTimeout(() => {
        if (this.formPayload.payments.length == 1) {
          this.formPayload.payments[0].cash_total_payment = this.transaction_query.total.toString();
        }

        const cashTotalPayment = (parseFloat(this.transaction_query.total) + this.totalAddtionalCost - this.totalNominal)

        this.formPayload.payments[index] = {
          ...this.payments[index],
          cash_total_payment: this.rupiah(cashTotalPayment),
        };
        
        for (
          let indexPayment = 0;
          indexPayment < this.formPayload.payments.length;
          indexPayment++
        ) {
          const element = this.formPayload.payments[indexPayment];
          if (element.payment_method == "piutang") {
            this.formPayload.payments[indexPayment] = {
              ...element,
              payment_customer_uuid: this.customer_uuid || "",
              piutang_tempo: localStorage.getItem("terms_of_payment_day") || "",
            };
          }
        }

        this.disabledAddPaymentMethod()

        this.validationAmount();

        if (document.getElementById(`nominal--${index}`)) {
          document.getElementById(`nominal--${index}`).innerHTML = "Rp 0";
        }
      }, 500);
    },
    paymentMethods(method) {
      const paymentMapping = {
        tunai: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    addPayments() {
      this.payments.push({
        payment_method: null,
        cash_total_payment: ''
      });
      this.formPayload.payments.push({
        payment_method: null,
        cash_total_payment: ''
      });
      this.updatedOptions();
    },
    updatedOptions() {
      const hasCustomerUuid = !!this.customer_uuid;

      const tunaiCount = this.formPayload.payments.filter((payment) => payment.payment_method === "tunai").length;

      const updateOptions = this.options.map((option) => {
        if (option.value === "piutang" || option.value === "wallet") {
          return { ...option, disabled: !hasCustomerUuid };
        }
        if (option.value === "tunai") {
          return { ...option, disabled: tunaiCount >= 1 };
        }
        return option;
      });

      this.options = updateOptions;
    },
    validationAmount() {
      const payments = this.formPayload.payments;
      const total = Object.values(payments).reduce((acc, element) => {
          const cashTotalPayment =
              parseFloat(preformatFloat(element.cash_total_payment)) || 0;
          return acc + cashTotalPayment;
      }, 0);

      this.totalNominal = total;
    },
    validateNominal(nominal, limit) {
      if (!this.formPayload.payments.some((el) => el.payment_method == "tunai") && nominal > limit) {
        this.swalOptions.text = "Jumlah nominal tidak boleh lebih dari total belanja";
      } else if (nominal < limit) {
        this.swalOptions.text = "Jumlah nominal tidak boleh kurang dari total belanja";
      } else {
        return {
          code: 200,
          messages: "Sukses",
        };
      }
      // Misal harga total 230.000, user bayar pake tunai sebesar
      this.showWarningMessage();

      return {
        code: 400,
        messages: "Galat",
      };
    },
    showWarningMessage() {
      this.$swal(this.swalOptions);
    },
    showAlertValidation() {
      const totalNominal = this.totalNominal;
      let res = this.validateNominal(totalNominal, parseFloat(this.transaction_query.total) + this.totalAddtionalCost );

      return res;
    },
    async createTransaction() {
      delete this.formPayload.cash_total_payment;

      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin melakukan Transaksi ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          let res = await this.showAlertValidation();

          if (res.code == 200) {
            this.$store.commit("checkout/setIsLoading", true);
            let form = this.preparePayload();
            this.$store
              .dispatch("checkout/submitCheckout", {
                uuid: localStorage.getItem("cartUuid"),
                payload: form,
              })
              .then((result) => {
                this.$store.commit("formWizard/setStep", 1);
                const item = result.data.data;
                const payment_status = {
                  uuid: item.uuid,
                  cash_change: item.cash_change,
                  total: item.total,
                  amount: this.transaction_query.amount_dp || 0,
                  type_payment: this.transaction_query.type_payment,
                }

                const itemsToRemove = [
                  "cartUuid",
                  "customer_type",
                  "customer_uuid",
                  "cash_total_payment",
                  "payment_method",
                  "price",
                  "poSO",
                  "payloadTransaksi",
                  'transaction_query'
                ];

                for (const item of itemsToRemove) {
                  localStorage.removeItem(item);
                }

                localStorage.setItem('payment_status', JSON.stringify(payment_status))
                this.$store.commit("checkout/setIsLoading", false);
                this.getActiveCart()
                this.$router.replace({ query: { s: 2, invoice_id: item.uuid } });
              })
              .catch((err) => {
                this.$store.commit("checkout/setIsLoading", false);
                // Handle errors
              });
          }
        }
      });
    },
    isDisabledProcess() {
      const hasNonCashPayment = this.formPayload.payments.some(
        (el) => el.payment_method !== "tunai"
      );
      const hasExcessivePayment = this.formPayload.payments.some(
        (el) =>
          parseFloat(preformatFloat(el.cash_total_payment)) >
          parseFloat(this.transaction_query.total) + this.totalAddtionalCost
      );

      if (!hasNonCashPayment) {
        return false;
      }
      return hasNonCashPayment && hasExcessivePayment;
    },
    disabledAddPaymentMethod() {
      const { totalAddtionalCost, totalNominal, formPayload, transaction_query } = this;
      const total = parseFloat(transaction_query.total) + totalAddtionalCost;

      return (
        total - totalNominal <= 0 ||
        formPayload.payments.some((el) => el.cash_total_payment == "")
      );
    },
    deletePayment(index) {
      let payments = JSON.parse(localStorage.getItem("payments"));
      const decoy = payments.splice(index, 1);

      this.formPayload.payments.splice(index, 1);
      this.payments.splice(index, 1);

      this.is_show_overlay = true;

      setTimeout(() => {
        this.formPayload.payments = payments;
        this.is_show_overlay = false;
      }, 500);

      this.updatedOptions();
    },
    preparePayload() {
      this.formPayload.customer_uuid = localStorage.getItem("customer_uuid");
      const arrPayments = Object.values(this.formPayload.payments);

      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key !== "additional_fees" && key !== "payments") {
            form.append(key, this.formPayload[key]);
          }
          if (key === "payments") {
            arrPayments.forEach((element, index) => {
              element.payment_customer_uuid = this.customer_uuid;
              for (const keyPayment in element) {
                if (
                  Object.hasOwnProperty.call(element, keyPayment) &&
                  element[keyPayment]
                ) {
                  const item = element[keyPayment];
                  form.append(
                    `payments[${index}][${
                      keyPayment === "type"
                        ? "payment_method"
                        : keyPayment === "cash_total_payment"
                        ? "amount"
                        : keyPayment
                    }]`,
                    item === "tunai"
                      ? "cash"
                      : keyPayment === "cash_total_payment"
                      ? parseFloat(preformatFloat(item))
                      : item
                  );
                }
              }
            });
          }
          if (key === "additional_fees") {
            for (const keySecond in this.formPayload[key]) {
              if (
                this.formPayload[key].hasOwnProperty(keySecond) &&
                this.formPayload[key][keySecond] != null
              ) {
                for (const keyThird in this.formPayload[key][keySecond]) {
                  if (
                    Object.hasOwnProperty.call(
                      this.formPayload[key][keySecond],
                      keyThird
                    )
                  ) {
                    const element = this.formPayload[key][keySecond][keyThird];
                    form.append(
                      `additional_fees[${keySecond}][${keyThird}]`,
                      keyThird === "additional_fee_note"
                        ? element
                        : parseFloat(preformatFloat(element))
                    );
                  }
                }
              }
            }
          }
        }
      }
      return form;
    },
    updatePayment(value, index, paymentMethod) {
        this.watchs.push(value);
        this.formPayload.payments[index] = {
            ...value,
            payment_method: paymentMethod,
        };
        const cashTotalPayment = value.cash_total_payment || "0";
        document.getElementById(`nominal--${index}`).innerHTML = `Rp ${!cashTotalPayment.includes(".") ? cashTotalPayment : cashTotalPayment
            }`;
        localStorage.setItem(
            "payments",
            JSON.stringify(this.formPayload.payments)
        );
    },
    getPayload(value, index) {
      this.updatePayment.call(this, value, index, "tunai");
    },
    getPayloadDeposit(value, index) {
      this.updatePayment.call(this, value, index, "wallet");
    },
    getPayloadGiro(value, index) {
      this.updatePayment.call(this, value, index, "giro");
    },
    getPayloadTransferBank(value, index) {
      this.updatePayment.call(this, value, index, "transfer");
    },
    getPayloadKredit(value, index) {
      this.updatePayment.call(this, value, index, "card");
    },
    getPayloadPiutang(value, index) {
      this.updatePayment.call(this, value, index, "piutang");
    },
    getPayloadBankAccountDetail(value, index) {
      this.formPayload.payments[index] = {
        ...this.formPayload.payments[index],
        account_detail: {
          ...value,
        },
      };
      this.payments[index] = {
        ...this.payments[index],
        account_detail: {
          ...value,
        },
      };
      this.bankAccountName();
      // this.updatePayment.call(this, value, index, 'transfer');
    },
    directionPage() {
        const transaction_query = {
            ...this.transaction_query,
            data: this.formPayload.payments
        }
        localStorage.setItem('transaction_query', JSON.stringify(transaction_query))
        this.$router.replace({ query: { s: 1 } });
      // const queryParams = {
      //   date: this.transaction_query.date,
      //   bonus: this.transaction_query.bonus,
      //   total: this.transaction_query.total,
      //   deliverypickup: this.transaction_query.deliverypickup,
      //   po_so_number: this.transaction_query.po_so_number,
      //   type_payment: this.transaction_query.type_payment,
      //   amount_dp: this.transaction_query.amount_dp,
      //   data: encodeURIComponent(JSON.stringify(this.formPayload.payments)),
      //   seller: this.transaction_query.seller
      // };
      // history.back();
      // this.$router.push({ name: 'payment.index', query: queryParams })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";

.p-2.bg-gray-2 {
  background-color: #fff !important;
}

.pt-5.bg-gray-2 {
  background-color: unset !important;
}

.mt-unset {
  .mt-3 {
    margin-top: unset !important;
  }
}

// .bg-white {
//   background-color: #f6f7fa !important;
// }

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow {
      border-color: #334155;
    }
  }
}

.form-payment {
  .image-box {
    width: 46px;
    height: 46px;
  }

  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }

  .container--multiple__payment {
    .container {
      padding: unset;

      .row {
        margin: unset;

        .col-12 {
          padding: unset;
        }

        .col-7 {
          padding-right: unset;
        }

        .col-8 {
          padding-left: unset;
        }

        .col-5 {
          padding-left: unset;
        }

        .col-4 {
          padding-right: unset;
        }
      }
    }
  }

  .container--split__preview {
    position: sticky;
    top: calc(50% - 80px);
    left: calc(50% - 80px);

    .preview--detail {
      width: 75%;

      @media screen and (max-height: 799px) and (orientation: landscape) {
        width: 100%;

        .size14 {
          font-size: 12px !important;
        }
      }
    }
  }
}

.container--split {
  margin: 0 125px;

  @media screen and (max-width: 992px) {
    margin: 0 100px;
  }

  @media screen and (max-width: 800px) {
    margin: 0 50px;
  }

  @media screen and (max-width: 600px) {
    margin: 0 80px;
  }

  @media screen and (max-width: 480px) {
    margin: 0 30px;
  }
}
</style>
